import { createTheme, lighten } from '@mui/material/styles';
import { PaletteTonalOffset } from '@mui/material/styles/createPalette';
import { common } from '@mui/material/colors';
import { esES } from '@mui/material/locale';
import type {} from '@mui/lab/themeAugmentation';

import { CustomThemePalette, CustomThemePaletteOptions } from '~globals/theme';
import { createAugmentColor } from '~utils/theme';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends CustomThemePalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends CustomThemePaletteOptions {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    tertiary: true;
  }
}

const tonalOffset: PaletteTonalOffset = 0.2;
const contrastThreshold = 3;

const theme = createTheme(
  {
    palette: {
      tonalOffset,
      contrastThreshold,
      // Principals
      primary: { main: '#5618b6', contrastText: common.white },
      secondary: { main: '#455a64', contrastText: common.white },
      success: { main: '#1DB45A', contrastText: common.white },
      warning: { main: '#DEA500', contrastText: common.white },
      error: { main: '#EB0014', contrastText: common.white },
      background: { default: '#e1e5ec' },
      // Customs
      tertiary: createAugmentColor({
        mainColor: '#b1b8bf',
        tonalOffset,
        contrastText: common.white,
        contrastThreshold,
      }),
    },
    typography: {
      fontFamily: [
        '"Montserrat"',
        '"Roboto"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif',
      ].join(','),
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            display: 'grid',
            minHeight: '100vh',
          },
          legend: {
            maxWidth: 0,
          },
          label: {
            marginBottom: 0,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: 'medium',
          variant: 'outlined',
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiLoadingButton: {
        defaultProps: {
          size: 'medium',
          variant: 'outlined',
          disableElevation: true,
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          variant: 'outlined',
          color: 'secondary',
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: 14,
          },
          outlined: ({ ownerState }) => ({
            zIndex: 1,
            pointerEvents: 'none',
            transform: 'translate(12px, 16px) scale(1)',
            maxWidth: 'calc(100% - 24px)',

            ...(ownerState.size === 'small' && {
              transform: 'translate(12px, 13px) scale(1)',
            }),

            ...(ownerState.shrink && {
              userSelect: 'none',
              pointerEvents: 'auto',
              transform: 'translate(12px, 7px) scale(0.75)',
              maxWidth: 'calc(133% - 24px)',

              ...(ownerState.size === 'small' && {
                transform: 'translate(12px, 4px) scale(0.75)',
              }),
            }),
          }),
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: ({ theme: currentTheme, ownerState }) => ({
            fontSize: currentTheme.typography.pxToRem(14),

            ...(ownerState?.multiline && {
              paddingTop: 25,
              paddingRight: 12,
              paddingBottom: 8,
              paddingLeft: 12,

              ...(ownerState?.size === 'small' && {
                paddingTop: 21,
                paddingBottom: 4,
              }),
            }),
          }),
          notchedOutline: {
            '& legend': {
              width: 0,
            },
          },
          input: ({ ownerState }) => ({
            paddingTop: 25,
            paddingRight: 12,
            paddingBottom: 8,
            paddingLeft: 12,

            ...(ownerState?.size === 'small' && {
              paddingTop: 21,
              paddingBottom: 4,
            }),

            ...(ownerState?.multiline && {
              padding: 0,
            }),

            '&[type="password"]': {
              font: 'small-caption',
              fontSize: 'inherit',
            },
          }),
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'none',
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.underline === 'always' && {
              '&:hover': {
                textDecoration: 'underline',
              },
            }),

            ...(ownerState.underline === 'hover' && {
              '&:hover': {
                textDecoration: 'underline',
              },
            }),
          }),
        },
      },
      MuiCard: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            padding: currentTheme.spacing(1, 2),
          }),
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            padding: currentTheme.spacing(1, 2),
          }),
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            padding: currentTheme.spacing(1, 2),
          }),
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            padding: currentTheme.spacing(1.5, 3),
          }),
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            backgroundColor: currentTheme.palette.common.white,
          }),
          colorDefault: ({ theme: currentTheme }) => ({
            backgroundColor: currentTheme.palette.grey[400],
          }),
        },
      },
      MuiFormControlLabel: {
        defaultProps: {
          componentsProps: {
            typography: { variant: 'body2' },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          outlined: ({ theme: currentTheme, ownerState }) => {
            const currentColor =
              ownerState.color || ownerState.severity || 'success';

            return {
              backgroundColor: lighten(
                currentTheme.palette[currentColor].light,
                0.9,
              ),
            };
          },
        },
      },
    },
  },
  esES,
);

export default theme;
