import { FC, lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import { PATHS } from '~constants/paths';
import { ComponentWithSuspense } from '~components/index';

const HomePage = lazy(() => import('./modules/Home'));
const CompanyPage = lazy(() => import('./modules/Company'));
const SearchAndRedirectToOrder = lazy(
  () => import('./modules/SearchAndRedirectToOrder'),
);
const SearchAndRedirectToOrderSurvey = lazy(
  () => import('./modules/SearchAndRedirectToOrderSurvey'),
);
const OrderPage = lazy(() => import('./modules/Order'));
const SurveyPage = lazy(() => import('./modules/Survey'));
const NotFoundPage = lazy(() => import('./modules/NotFound'));

const routes: RouteObject[] = [
  {
    children: [
      {
        path: PATHS.HOME,
        element: <ComponentWithSuspense component={<HomePage />} />,
      },
      {
        path: PATHS.SEARCH_REDIRECT_ORDER,
        element: (
          <ComponentWithSuspense component={<SearchAndRedirectToOrder />} />
        ),
      },
      {
        path: PATHS.SEARCH_REDIRECT_ORDER_SURVEY,
        element: (
          <ComponentWithSuspense
            component={<SearchAndRedirectToOrderSurvey />}
          />
        ),
      },
      {
        path: PATHS.COMPANY,
        element: <ComponentWithSuspense component={<CompanyPage />} />,
      },
      {
        path: PATHS.ORDER,
        element: <ComponentWithSuspense component={<OrderPage />} />,
      },
      {
        path: PATHS.SURVEY,
        element: <ComponentWithSuspense component={<SurveyPage />} />,
      },
      {
        path: '*',
        element: <ComponentWithSuspense component={<NotFoundPage />} />,
      },
    ],
  },
];

const BaseRoutes: FC = () => {
  const pages = useRoutes(routes);

  return pages;
};

export default BaseRoutes;
