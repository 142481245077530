import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import { isDevelopment } from '~utils/env';

import rootReducers from './slices';

const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware();

    if (isDevelopment) {
      return defaultMiddleware.concat(logger);
    }

    return defaultMiddleware;
  },
  devTools: isDevelopment,
});

export default store;
