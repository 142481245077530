import { ReactElement } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Close as CloseIcon,
  ArrowRight as ArrowRightIcon,
} from '@mui/icons-material';

import { ArticlesDialogProps } from './types';
import {
  ArticlesDialogContainer,
  ArticlesDialogTitle,
  ArticlesDialogTitleCloseIcon,
  ArticlesDialogContent,
} from './styles';

const ArticlesDialog = ({
  open,
  onClose,
  articles,
}: ArticlesDialogProps): ReactElement => {
  return (
    <ArticlesDialogContainer open={open} maxWidth="md">
      <ArticlesDialogTitle>
        <Typography component="h2" variant="h6">
          Artículos
        </Typography>

        <ArticlesDialogTitleCloseIcon
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </ArticlesDialogTitleCloseIcon>
      </ArticlesDialogTitle>

      <ArticlesDialogContent dividers>
        <List dense disablePadding>
          {articles.map((articleItem) => (
            <ListItem
              key={`article-${articleItem.itemId}-${articleItem.itemTaskId}`}
              disablePadding
            >
              <ListItemIcon sx={{ minWidth: 0 }}>
                <ArrowRightIcon />
              </ListItemIcon>

              <ListItemText primary={articleItem.description} />
            </ListItem>
          ))}
        </List>
      </ArticlesDialogContent>
    </ArticlesDialogContainer>
  );
};

export default ArticlesDialog;
