import { ReactElement } from 'react';
import { Typography } from '@mui/material';

import { DeliveryCodeInfoProps } from './types';
import { DeliveryCodeInfoContainer } from './styles';

const DeliveryCodeInfo = ({ code }: DeliveryCodeInfoProps): ReactElement => (
  <DeliveryCodeInfoContainer>
    <Typography variant="body1" component="p" color="inherit">
      Código de visita: <b>{code}</b>
    </Typography>
  </DeliveryCodeInfoContainer>
);

export default DeliveryCodeInfo;
