import { ReactElement, useRef, useMemo, useEffect } from 'react';
import WrapperMap, { MapRef, Marker } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import { LngLatBounds, FitBoundsOptions } from 'mapbox-gl';
import { useMountedState } from 'react-use';

import { useAppSelector } from '~hooks/index';
import { selectOrderState } from '~store/slices/order';
import { toAbsoluteUrl } from '~utils/assets';
import { Nullable } from '~globals/commons';

import { MapContainer, MapMarkerIcon } from './styles';

const API_KEY = process.env.REACT_APP_MAP_TILE_API_KEY;

interface MapCoords {
  longitude: number;
  latitude: number;
}

const defaultFitBoundsOptions: FitBoundsOptions = {
  padding: {
    top: 140,
    bottom: 100,
    left: 100,
    right: 100,
  },
  maxZoom: 16,
};

const getParseCoords = (coords: MapCoords) =>
  new maplibregl.LngLat(coords.longitude, coords.latitude);

const Map = (): ReactElement => {
  const isMounted = useMountedState();
  const mapRef = useRef<Nullable<MapRef>>(null);

  const { data: currentOrder } = useAppSelector(selectOrderState);

  const orderCoords = useMemo<Nullable<MapCoords>>(() => {
    if (currentOrder?.itemLongitude && currentOrder?.itemLatitude) {
      return {
        longitude: currentOrder.itemLongitude,
        latitude: currentOrder.itemLatitude,
      };
    }

    return null;
  }, [currentOrder?.itemLongitude, currentOrder?.itemLatitude]);

  const vehicleCoords = useMemo<Nullable<MapCoords>>(() => {
    if (currentOrder?.vehicleLongitude && currentOrder?.vehicleLatitude) {
      return {
        longitude: currentOrder.vehicleLongitude,
        latitude: currentOrder.vehicleLatitude,
      };
    }

    return null;
  }, [currentOrder?.vehicleLongitude, currentOrder?.vehicleLatitude]);

  const bounds = useMemo(() => {
    const currentBounds =
      new maplibregl.LngLatBounds() as unknown as LngLatBounds;

    if (orderCoords) {
      currentBounds.extend(getParseCoords(orderCoords));
    }

    if (vehicleCoords) {
      currentBounds.extend(getParseCoords(vehicleCoords));
    }

    return currentBounds;
  }, [orderCoords, vehicleCoords]);

  useEffect(() => {
    if (isMounted() && orderCoords && vehicleCoords) {
      mapRef.current?.fitBounds(
        [getParseCoords(orderCoords), getParseCoords(vehicleCoords)],
        defaultFitBoundsOptions,
      );
    }
  }, [isMounted, orderCoords, vehicleCoords]);

  return (
    <MapContainer>
      <WrapperMap
        ref={mapRef}
        mapLib={maplibregl}
        mapStyle={`https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`}
        initialViewState={{
          bounds,
          fitBoundsOptions: defaultFitBoundsOptions,
        }}
        interactive={false}
      >
        {orderCoords && (
          <Marker {...orderCoords} anchor="bottom">
            <MapMarkerIcon
              src={toAbsoluteUrl('/images/pin_home.png')}
              alt="Icon Home"
            />
          </Marker>
        )}

        {vehicleCoords && (
          <Marker {...vehicleCoords} anchor="bottom">
            <MapMarkerIcon
              src={toAbsoluteUrl('/images/pin_truck.png')}
              alt="Icon Truck"
            />
          </Marker>
        )}
      </WrapperMap>
    </MapContainer>
  );
};

export default Map;
