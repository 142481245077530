import { styled, Box, Link } from '@mui/material';

export const LayoutSurveyContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  minHeight: '100vh',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  gridTemplateRows: 'auto 1fr auto',
  justifyItems: 'center',
}));

export const LayoutSurveyContentContainer = styled(Box)({
  width: '100%',
  display: 'grid',
  justifyItems: 'center',
});

export const LayoutSurveyFooterContainer = styled(Box)({
  width: '100%',
  display: 'grid',
  justifyItems: 'center',
});

export const LayoutSurveyFooterLink = styled(Link)({
  display: 'grid',
  justifyItems: 'center',
});

export const LayoutSurveyFooterLogo = styled('img')(({ theme }) => ({
  width: 80,
  height: 35,

  [theme.breakpoints.up('md')]: {
    width: 100,
    height: 35,
  },
}));
