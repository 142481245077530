import { styled, Box, Link } from '@mui/material';
import { toAbsoluteUrl } from '~utils/assets';

const heroArea = 'layout-hero-area';
const mainArea = 'layout-main-area';

export const classes = {
  container: 'Layout-container',
  hero: 'Layout-hero',
  main: 'Layout-main',
  mainContent: 'Layout-main-content',
  mainFooter: 'Layout-main-footer',
};

export const LayoutContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas: `"${heroArea}" "${mainArea}"`,
  gridTemplateRows: 'max(50%, 382px) 1fr',
  gridTemplateColumns: '1fr',
  minHeight: '100vh',
  position: 'relative',
  overflowY: 'visible',

  [theme.breakpoints.up('md')]: {
    gridTemplateAreas: `"${mainArea} ${heroArea}"`,
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'max(31%, 327px)  1fr',
    maxHeight: '100vh',
    overflowY: 'hidden',
  },
}));

export const LayoutHeroContainer = styled(Box)({
  gridArea: heroArea,
});

export const LayoutHeroImg = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundImage: `url(${toAbsoluteUrl('/images/cover.png')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '150% 128%',
  backgroundPosition: 'left 60% top 0%',

  [theme.breakpoints.up('md')]: {
    backgroundSize: 'cover',
    backgroundPosition: 'right top',
  },
}));

export const LayoutMainContainer = styled(Box)(({ theme }) => ({
  gridArea: mainArea,
  padding: theme.spacing(4),
  paddingTop: theme.spacing(6),
  display: 'grid',
  gap: theme.spacing(2),
  alignContent: 'space-between',
  justifyItems: 'center',
  gridTemplateRows: '1fr auto',
  overflowY: 'visible',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(4),
    gridTemplateRows: 'auto 1fr auto',
    overflowY: 'auto',
  },
}));

export const LayoutMainContentContainer = styled(Box)({
  display: 'grid',
});

export const LayoutMainFooterContainer = styled(Box)({
  display: 'grid',
  justifyItems: 'center',
});

export const LayoutFooterLink = styled(Link)({
  display: 'grid',
  justifyItems: 'center',
});

export const LayoutFooterLogo = styled('img')(({ theme }) => ({
  width: 80,
  height: 35,

  [theme.breakpoints.up('md')]: {
    width: 100,
    height: 35,
  },
}));
