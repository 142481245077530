import { styled, Box, Avatar } from '@mui/material';

import { CompanyInfoProps } from './types';

interface CompanyInfoOwnerState {
  ownerState: Pick<CompanyInfoProps, 'roundedMobile' | 'floatMobile'>;
}

export const CompanyInfoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<CompanyInfoOwnerState>(({ theme, ownerState }) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1),
  textAlign: 'center',
  fontSize: theme.typography.pxToRem(15),
  width: '100%',

  ...(ownerState.roundedMobile && {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
  }),

  ...(ownerState.floatMobile && {
    width: 'auto',
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 2,
  }),

  [theme.breakpoints.between('sm', 'md')]: {
    width: '100%',
    maxWidth: 300,

    ...(ownerState.floatMobile && {
      left: '50%',
      right: '50%',
      transform: 'translateX(-50%)',
    }),
  },

  [theme.breakpoints.up('md')]: {
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    fontSize: theme.typography.pxToRem(20),
    padding: 0,

    ...(ownerState.roundedMobile && {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }),

    ...(ownerState.floatMobile && {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      right: 'auto',
    }),
  },
}));

export const CompanyInfoAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  fontSize: theme.typography.pxToRem(20),
  boxShadow: theme.shadows[1],

  [theme.breakpoints.up('md')]: {
    width: 70,
    height: 70,
    fontSize: theme.typography.pxToRem(28),
  },
}));
