import { createRoot } from 'react-dom/client';

import App from '~app/index';

import reportWebVitals from './reportWebVitals';

import 'maplibre-gl/dist/maplibre-gl.css';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
