import { styled, Box } from '@mui/material';

export const EstimatedDeliveryInfoContainer = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[4],
  backgroundColor: theme.palette.background.paper,
  display: 'grid',
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  justifyItems: 'center',
  position: 'absolute',
  top: 'calc(-48px - 30px)',
  width: 230,
  margin: 0,
  left: '50%',
  right: '50%',
  transform: 'translateX(-50%)',
  zIndex: 2,

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
