import { SelectOption } from '~globals/commons';
import { SurveyRatings } from '~globals/enums';

export const getRatingOptions = (isFeminine = false): SelectOption[] => [
  { label: isFeminine ? 'Mala' : 'Malo', value: SurveyRatings.Bad },
  { label: 'Mejorable', value: SurveyRatings.Improvable },
  {
    label: isFeminine ? 'Adecuada' : 'Adecuado',
    value: SurveyRatings.Appropriate,
  },
  { label: isFeminine ? 'Óptima' : 'Óptimo', value: SurveyRatings.Optimum },
];
