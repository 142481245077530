import API from '~config/api';
import { ApiPromise, DataResponse, ErrorResponse } from '~globals/api';

import { CompanyBase } from './types';

export const COMPANY_ENPOINTS = {
  getCompanyById: (companyId: string): string =>
    `/api/v1/Track/Company/${companyId}`,
};

export const getCompanyById = (
  companyId: string,
): ApiPromise<DataResponse<CompanyBase>, ErrorResponse> =>
  API.get<DataResponse<CompanyBase>, ErrorResponse>(
    COMPANY_ENPOINTS.getCompanyById(companyId),
  );
