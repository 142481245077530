export const PATHS = {
  HOME: '/',
  COMPANY: '/company/:companyId',
  SEARCH_REDIRECT_ORDER: '/:routeCode',
  SEARCH_REDIRECT_ORDER_SURVEY: '/:routeCode/survey',
  ORDER: '/company/:companyId/order/:orderId',
  SURVEY: '/company/:companyId/order/:orderId/survey',
};

export const EXTERNALS_URLS = {
  HELP_ARTICLE: 'https://routix.tawk.help/article/seguimiento-de-visitas',
  COMMERCIAL_PAGE: 'http://routix.io',
};
