import API from '~config/api';
import { ApiPromise, DataResponse, ErrorResponse } from '~globals/api';

import {
  OrderBase,
  OrderExtended,
  OrderSearch,
  OrderSearchBody,
} from './types';

export const ORDER_ENPOINTS = {
  searchOrder: '/api/v1/Track/RouteItem/GetRouteItemId',
  searchOrderByTrackingCode: (routeCode: string): string =>
    `/api/v1/Track/RouteItem/${routeCode}`,
  getOrderInfoById: (routeItemId: string): string =>
    `/api/v1/Track/RouteItem/InfoTracking/${routeItemId}`,
  getEvidencesOrderPDF: (routeCode: string): string =>
    `/api/v1/Report/EvidenceItemPDF/${routeCode}`,
};

export const searchOrder = (
  data: OrderSearchBody,
): ApiPromise<DataResponse<OrderBase>, ErrorResponse> =>
  API.post<DataResponse<OrderBase>, ErrorResponse>(
    ORDER_ENPOINTS.searchOrder,
    data,
  );

export const searchOrderByTrackingCode = (
  routeCode: string,
): ApiPromise<DataResponse<OrderSearch>, ErrorResponse> =>
  API.get<DataResponse<OrderSearch>, ErrorResponse>(
    ORDER_ENPOINTS.searchOrderByTrackingCode(routeCode),
  );

export const getOrderInfoById = (
  routeItemId: string,
): ApiPromise<DataResponse<OrderExtended>, ErrorResponse> =>
  API.get<DataResponse<OrderExtended>, ErrorResponse>(
    ORDER_ENPOINTS.getOrderInfoById(routeItemId),
  );

export const getEvidencesOrderPDF = (
  routeCode: string,
): ApiPromise<Blob, ErrorResponse> =>
  API.get<Blob, ErrorResponse>(
    ORDER_ENPOINTS.getEvidencesOrderPDF(routeCode),
    {},
    { responseType: 'blob', timeout: 120000 },
  );
