import { styled, Box } from '@mui/material';

export const DeliveryCodeInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  paddingTop: 1,
  paddingBottom: 1,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));
