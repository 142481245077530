import { styled, Box } from '@mui/material';

export const DescriptionWithIconBoxContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const DescriptionWithIconBoxImg = styled('img')({
  width: 60,
  height: 60,
});

export const DescriptionWithIconDescriptionContainer = styled(Box)(
  ({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(0.5),
  }),
);
