import API from '~config/api';
import { ApiPromise, DataResponse, ErrorResponse } from '~globals/api';

import { SurveyCreateBody, SurveyCreateResponse } from './types';

export const SURVEY_ENPOINTS = {
  validateExistsSurveyById: (routeItemId: string): string =>
    `/api/v1/Track/RouteItemSourvey/ExistsRouteItemSourvey/${routeItemId}`,
  createSurvery: '/api/v1/Track/RouteItem/RouteItemSourvey',
};

export const validateExistsSurveyById = (
  routeItemId: string,
): ApiPromise<DataResponse<boolean>, ErrorResponse> =>
  API.get<DataResponse<boolean>, ErrorResponse>(
    SURVEY_ENPOINTS.validateExistsSurveyById(routeItemId),
  );

export const createSurvery = (
  data: SurveyCreateBody,
): ApiPromise<DataResponse<SurveyCreateResponse>, ErrorResponse> =>
  API.post<DataResponse<SurveyCreateResponse>, ErrorResponse>(
    SURVEY_ENPOINTS.createSurvery,
    data,
  );
