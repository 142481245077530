import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import fileDownload from 'js-file-download';
import { replace } from 'lodash';

import { useLazyRequest } from '~hooks/index';
import { getEvidencesOrderPDF } from '~services/order';

interface DownloadEvidencesOrderPDFReturn {
  loading: boolean;
  onDownloadPDF: () => Promise<void>;
}

export const useDownloadEvidencesOrderPDF = (
  routeCode?: string,
): DownloadEvidencesOrderPDFReturn => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingDownload, , executeDownload] = useLazyRequest({
    request: getEvidencesOrderPDF,
    withPostSuccess: ({ data: dataExportResponse }) => {
      if (dataExportResponse) {
        const parseRouteCode = routeCode ? replace(routeCode, /-/g, '') : '';

        const fileName = `comprobante-visita-${parseRouteCode}.pdf`;

        fileDownload(dataExportResponse, fileName);

        enqueueSnackbar(
          `Se ha descargado el comprobante de visita correctamente`,
          { variant: 'success' },
        );
      }
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'No se pudo descargar el comprobante de visita, intente nuevamente',
        { variant: 'error' },
      );
    },
  });

  const onDownloadPDF = useCallback(async () => {
    if (routeCode) await executeDownload(routeCode);
  }, [executeDownload, routeCode]);

  return { loading: loadingDownload, onDownloadPDF };
};
