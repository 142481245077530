import * as Yup from 'yup';

import { VALIDATIONS_ERROR } from '~constants/validationsError';

export const FIELDS_NAME = {
  TRACKING_CODE: 'trackingCode',
} as const;

export const initialValues = {
  [FIELDS_NAME.TRACKING_CODE]: 'RO-########-########',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.TRACKING_CODE]: Yup.string().matches(
    /RO-[0-9a-fA-F]{8}-[0-9a-fA-F]{8}/,
    { message: VALIDATIONS_ERROR.INVALID_FORMAT },
  ),
});
