import { ReactElement } from 'react';
import { Typography } from '@mui/material';

import { DescriptionWithIconBoxProps } from './types';
import {
  DescriptionWithIconBoxContainer,
  DescriptionWithIconBoxImg,
  DescriptionWithIconDescriptionContainer,
} from './styles';

const DescriptionWithIconBox = ({
  iconUrl,
  label,
  description,
}: DescriptionWithIconBoxProps): ReactElement => (
  <DescriptionWithIconBoxContainer>
    <DescriptionWithIconBoxImg src={iconUrl} alt={label} />

    <DescriptionWithIconDescriptionContainer>
      <Typography variant="body2" fontWeight={500} color="text.secondary">
        {label}
      </Typography>

      <Typography variant="body1" fontWeight={500} color="primary">
        {description}
      </Typography>
    </DescriptionWithIconDescriptionContainer>
  </DescriptionWithIconBoxContainer>
);

export default DescriptionWithIconBox;
