import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../types';
import { OrderState } from './types';

const initialState: OrderState = {
  loading: true,
  data: null,
  error: null,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    fetchOrder: (state) => {
      state.loading = true;
    },
    fetchedOrder: (state, action: PayloadAction<OrderState['data']>) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchingErrorOrder: (state, action: PayloadAction<OrderState['error']>) => {
      state.loading = true;
      state.data = null;
      state.error = action.payload;
    },
    resetOrder: () => initialState,
  },
});

export const { fetchOrder, fetchedOrder, fetchingErrorOrder, resetOrder } =
  orderSlice.actions;

export const selectOrderState = (state: RootState): OrderState => state.order;

export default orderSlice.reducer;
