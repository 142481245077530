import { ReactElement, Suspense } from 'react';

import { ComponentWithSuspenseProps } from './types';
import LoadingFallback from '../LoadingFallback';

const ComponentWithSuspense = ({
  component: Component,
}: ComponentWithSuspenseProps): ReactElement => (
  <Suspense fallback={<LoadingFallback />}>{Component}</Suspense>
);

export default ComponentWithSuspense;
