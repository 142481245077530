import {
  styled,
  Stepper,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  stepLabelClasses,
  CircularProgress,
  Box,
} from '@mui/material';

export const StepperOrderStateStepper = styled(Stepper)({
  maxWidth: '100%',
});

export const StepperOrderStateStepConnector = styled(StepConnector)(
  ({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: '4px 0',

    [`& .${stepConnectorClasses.line}`]: {
      height: 5,
      border: 0,
      backgroundColor: theme.palette.common.white,
      borderRadius: 1,
    },

    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.primary.main,
      },
    },

    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);

export const StepperOrderStateStep = styled(Step)({
  padding: 0,
});

export const StepperOrderStateStepLabel = styled(StepLabel)({
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0,
  },
});

export const StepperOrderStateStepIconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: 60,
  height: 60,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}));

export const StepperOrderStateStepIconBoxStatus = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.tertiary.main,
  color: theme.palette.common.white,
  width: 42,
  height: 42,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',

  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
  }),

  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

export const StepperOrderStateStepIconBoxProgress = styled(CircularProgress)(
  ({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.palette.primary.main,
  }),
);
