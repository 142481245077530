import { ReactElement, useMemo } from 'react';

import { Nullable } from '~globals/commons';
import { ComplaintChannelTypeId } from '~globals/enums';

import {
  ComplaintChannelButtonProps,
  ComplaintChannelBaseButtonProps,
} from './types';
import { getLinkHrefEmail, getLinkHrefWhatsapp } from './utils';
import { ComplaintChannelButtonRoot } from './styles';

const ComplaintChannelButton = ({
  channelTypeId,
  address,
  orderTrackingCode,
}: ComplaintChannelButtonProps): Nullable<ReactElement> => {
  const buttonProps = useMemo<Nullable<ComplaintChannelBaseButtonProps>>(() => {
    if (channelTypeId === ComplaintChannelTypeId.None || !address) return null;

    const props: ComplaintChannelBaseButtonProps = {
      target: '_blank',
      rel: 'noopener noreferrer',
    };

    if (channelTypeId === ComplaintChannelTypeId.Email) {
      props.href = getLinkHrefEmail(address, orderTrackingCode);
    }

    if (channelTypeId === ComplaintChannelTypeId.Whatsapp) {
      props.href = getLinkHrefWhatsapp(address, orderTrackingCode);
    }

    return props;
  }, [channelTypeId, address, orderTrackingCode]);

  if (!buttonProps) return null;

  return (
    <ComplaintChannelButtonRoot
      variant="contained"
      color="primary"
      size="large"
      {...buttonProps}
    >
      Tengo un problema
    </ComplaintChannelButtonRoot>
  );
};

export default ComplaintChannelButton;
