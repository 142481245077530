import { styled, Box } from '@mui/material';

export const MapContainer = styled(Box)({
  width: '100%',
  height: '100%',
});

export const MapMarkerIcon = styled('img')({
  width: 38,
  height: 50,
});
