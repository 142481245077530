import { styled, Button } from '@mui/material';

import { ComplaintChannelBaseButtonProps } from './types';

export const ComplaintChannelButtonRoot = styled(
  (props: ComplaintChannelBaseButtonProps) => (
    <Button {...props} component="a" />
  ),
)({
  maxWidth: 300,
  width: '100%',
});
