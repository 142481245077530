import { forwardRef, useCallback } from 'react';
import { IMaskInput } from 'react-imask';

import { TrackingCodeInputMaskProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TrackingCodeInputMask = forwardRef<any, TrackingCodeInputMaskProps>(
  function TrackingCodeInputMask(props, ref) {
    const { onChange, onBlur, value, name, ...other } = props;

    const handleChange = useCallback(
      (nextVal: string) => {
        onChange({ target: { name, value: nextVal } });
      },
      [onChange, name],
    );

    const handleBlur = useCallback(() => {
      onBlur?.({ target: { name } });
    }, [onBlur, name]);

    return (
      <IMaskInput
        {...other}
        value={value}
        mask="RO-########-########"
        definitions={{
          '#': {
            mask: /[0-9a-fA-F]/,
            placeholderChar: '#',
            lazy: false,
          },
        }}
        inputRef={ref}
        onChange={() => {}}
        onAccept={handleChange}
        onBlur={handleBlur}
        overwrite="shift"
        lazy={false}
        placeholder="RO-########-########"
      />
    );
  },
);

export default TrackingCodeInputMask;
