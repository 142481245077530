import {
  styled,
  FormControl,
  FormLabel,
  RadioGroup,
  Box,
  FormControlLabel,
  formControlLabelClasses,
  Radio,
  radioClasses,
  svgIconClasses,
} from '@mui/material';

export const RatingContainer = styled(FormControl)({
  width: '100%',
});

export const RatingLabel = styled(FormLabel)(({ theme }) => ({
  ...theme.typography.body2,
}));

export const RatingRadioGroup = styled(RadioGroup)({
  position: 'relative',
  flexWrap: 'nowrap',
});

export const RatingRadioLabelContainer = styled(Box)({
  position: 'relative',
  flex: 1,
});

export const RatingRadioLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  minWidth: 80,

  [`& .${formControlLabelClasses.label}`]: {
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const RatingRadioRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.primary.main,

  [`& .${svgIconClasses.root}`]: {
    fontSize: 28,
  },

  [`&.${radioClasses.checked}`]: {
    color: theme.palette.primary.main,

    [`& .${svgIconClasses.root}:last-of-type`]: {
      transform: 'scale(1.7)',
    },
  },
}));

export const RatingRadioLabelConnectorContainer = styled(Box)({
  flex: '1 1 auto',
  position: 'absolute',
  top: 21,
  left: 'calc(-100% + 51px)',
  right: 'calc(100% - 29px)',
});

export const RatingRadioLabelConnectorContent = styled('span')(({ theme }) => ({
  display: 'block',
  borderColor: theme.palette.primary.main,
  borderTopStyle: 'solid',
  borderTopWidth: 4,
}));
