import { styled, Box } from '@mui/material';

export const SurveyFormContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  width: '100%',
  maxWidth: '100%',
  gridTemplateRows: 'auto 1fr',
  gap: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    maxWidth: 500,
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: 590,
  },
}));

export const SurveyFormInfoContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
}));

export const SurveyFormInfoContentInfo = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    padding: theme.spacing(2, 0),
    marginTop: theme.spacing(1),
  },
}));
