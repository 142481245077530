import { getStringifyUrlQueryString } from '~utils/router';

export const getLinkHrefEmail = (
  email: string,
  trackingCode: string,
): string => {
  const url = getStringifyUrlQueryString({
    url: email,
    query: {
      subject: `Tengo un problema con la orden ${trackingCode}`,
    },
  });

  return `mailto:${url}`;
};

export const getLinkHrefWhatsapp = (
  phone: string,
  trackingCode: string,
): string => {
  const link = getStringifyUrlQueryString({
    url: 'https://api.whatsapp.com/send',
    query: {
      phone,
      text: `Hola! Tuve un problema con mi orden ${trackingCode}`,
    },
  });

  return link;
};
