import { ReactElement } from 'react';
import { StepIconProps, useStepContext } from '@mui/material';

import {
  StepperOrderStateStepIconContainer,
  StepperOrderStateStepIconBoxStatus,
  StepperOrderStateStepIconBoxProgress,
} from './styles';

const StepperOrderStateIcon = ({
  active,
  completed,
  icon,
  className,
}: StepIconProps): ReactElement => {
  // @ts-expect-error: Let's ignore a compile error like this unreachable code
  const { last } = useStepContext();

  return (
    <StepperOrderStateStepIconContainer>
      <StepperOrderStateStepIconBoxStatus
        ownerState={{ active, completed }}
        className={className}
      >
        {icon}
      </StepperOrderStateStepIconBoxStatus>

      {active && !completed && !last && (
        <StepperOrderStateStepIconBoxProgress size="inherit" />
      )}
    </StepperOrderStateStepIconContainer>
  );
};

export default StepperOrderStateIcon;
