import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../types';
import { CompanyState } from './types';

const initialState: CompanyState = {
  loading: true,
  data: null,
  error: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    fetchCompany: (state) => {
      state.loading = true;
    },
    fetchedCompany: (state, action: PayloadAction<CompanyState['data']>) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchingErrorCompany: (
      state,
      action: PayloadAction<CompanyState['error']>,
    ) => {
      state.loading = true;
      state.data = null;
      state.error = action.payload;
    },
    resetCompany: () => initialState,
  },
});

export const {
  fetchCompany,
  fetchedCompany,
  fetchingErrorCompany,
  resetCompany,
} = companySlice.actions;

export const selectCompanyState = (state: RootState): CompanyState =>
  state.company;

export default companySlice.reducer;
