import {
  styled,
  Dialog,
  dialogClasses,
  IconButton,
  DialogContent,
} from '@mui/material';

export const ArticlesDialogContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 600,
    width: '100%',
    maxHeight: 400,
  },
});

export const ArticlesDialogTitle = styled('div')(({ theme }) => ({
  flex: 'none',
  padding: theme.spacing(0.75, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const ArticlesDialogTitleCloseIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const ArticlesDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(1, 3),
}));
