import { ReactElement } from 'react';
import { Alert } from '@mui/material';

import { NotFoundOrderProps } from './types';
import { NotFoundOrderContainer, NotFoundOrderContent } from './styles';

const NotFoundOrder = ({
  loading,
  notFound,
}: NotFoundOrderProps): ReactElement => (
  <NotFoundOrderContainer loading={loading} validateShowMap={false}>
    <NotFoundOrderContent>
      {notFound && (
        <Alert variant="outlined" severity="warning" icon={false}>
          No hemos encontrado ningúna orden con el número de seguimiento
          ingresado. Por favor, verifica el número de seguimiento e intentalo
          nuevamente.
        </Alert>
      )}
    </NotFoundOrderContent>
  </NotFoundOrderContainer>
);

export default NotFoundOrder;
