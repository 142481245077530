import { ReactElement, useMemo } from 'react';
import { get } from 'lodash';

import { RouteItemStateType } from '~globals/enums';

import { StepperOrderStateProps, StepperOrderStateActiveStep } from './types';
import StepperOrderStateIcon from './StepIcon';
import {
  StepperOrderStateStepper,
  StepperOrderStateStepConnector,
  StepperOrderStateStep,
  StepperOrderStateStepLabel,
} from './styles';

const STEPS = [{ id: 'on-agenda' }, { id: 'inProgress' }, { id: 'delivered' }];

const STATE_ACTIVE_STEPS: Record<
  RouteItemStateType,
  StepperOrderStateActiveStep
> = {
  [RouteItemStateType.New]: 0,
  [RouteItemStateType.OnAgenda]: 0,
  [RouteItemStateType.InProgress]: 1,
  [RouteItemStateType.FinalizedSuccess]: 2,
  [RouteItemStateType.FinalizedError]: 2,
  [RouteItemStateType.WithoutVisiting]: 2,
};

const StepperOrderState = ({
  routeItemStateTypeId,
}: StepperOrderStateProps): ReactElement => {
  const activeStep = useMemo(
    () => get(STATE_ACTIVE_STEPS, routeItemStateTypeId),
    [routeItemStateTypeId],
  );

  return (
    <StepperOrderStateStepper
      activeStep={activeStep}
      orientation="horizontal"
      connector={<StepperOrderStateStepConnector />}
    >
      {STEPS.map(({ id }) => (
        <StepperOrderStateStep key={`stepper-generate-route-step-${id}`}>
          <StepperOrderStateStepLabel
            StepIconComponent={StepperOrderStateIcon}
          />
        </StepperOrderStateStep>
      ))}
    </StepperOrderStateStepper>
  );
};

export default StepperOrderState;
