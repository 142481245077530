import { ReactElement, useMemo } from 'react';
import { Typography } from '@mui/material';

import { useAppSelector } from '~hooks/index';
import { selectCompanyState } from '~store/slices/company';
import { selectOrderState } from '~store/slices/order';
import { CompanyInfo, LoadingFallback } from '~components/index';
import { EXTERNALS_URLS } from '~constants/paths';
import { toAbsoluteUrl } from '~utils/assets';

import { LayoutSurveyProps } from './types';
import {
  LayoutSurveyContainer,
  LayoutSurveyContentContainer,
  LayoutSurveyFooterContainer,
  LayoutSurveyFooterLink,
  LayoutSurveyFooterLogo,
} from './styles';

const LayoutSurvey = ({
  children,
  loading,
}: LayoutSurveyProps): ReactElement => {
  const { data: currenCompany } = useAppSelector(selectCompanyState);

  const { data: currentOrder } = useAppSelector(selectOrderState);

  const giverCompanyName = useMemo(
    () =>
      currentOrder?.giverCompanyId === currenCompany?.companyId
        ? undefined
        : currentOrder?.giverCompanyName,
    [currentOrder, currenCompany],
  );

  if (loading) return <LoadingFallback />;

  return (
    <LayoutSurveyContainer>
      {currenCompany && (
        <CompanyInfo
          name={currenCompany.companyName}
          urlAvatar={currenCompany.companyLogoUrl}
          giverCompanyName={giverCompanyName}
          roundedMobile
          floatMobile={false}
        />
      )}

      <LayoutSurveyContentContainer>{children}</LayoutSurveyContentContainer>

      <LayoutSurveyFooterContainer>
        <LayoutSurveyFooterLink
          href={EXTERNALS_URLS.COMMERCIAL_PAGE}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
        >
          <Typography variant="caption" color="text.secondary">
            Con la tecnología de
          </Typography>

          <LayoutSurveyFooterLogo
            src={toAbsoluteUrl('/logo.svg')}
            alt="Routix Logo"
          />
        </LayoutSurveyFooterLink>
      </LayoutSurveyFooterContainer>
    </LayoutSurveyContainer>
  );
};

export default LayoutSurvey;
