import moment, { MomentInput } from 'moment-timezone';

interface OptionFormatterDate {
  format?: string;
  withUtc?: boolean;
  withTimeZone?: boolean;
  parseToUtc?: boolean;
}

export const formatterDate = (
  date: MomentInput,
  options: OptionFormatterDate = {
    format: moment.HTML5_FMT.DATE,
    withUtc: false,
    withTimeZone: false,
    parseToUtc: false,
  },
): string => {
  let currentDate = moment(date);

  if (options.withUtc) {
    currentDate = moment.utc(date);
  }

  if (options.parseToUtc) {
    currentDate.utc();
  }

  if (options.withTimeZone) {
    currentDate.tz(moment.tz.guess());
  }

  if (currentDate.isValid()) {
    return currentDate.format(options.format);
  }

  return '';
};

interface OptionsFormmatterEstimatedTime {
  format?: { date?: string; time?: string };
  subtracFrom?: number;
  addUntil?: number;
}

interface EstimatedDateTimeItem {
  date: string;
  time: string;
}

interface EstimatedDateTime {
  from: EstimatedDateTimeItem;
  to: EstimatedDateTimeItem;
}

export const formatterEstimatedDateTime = (
  date: MomentInput,
  options: OptionsFormmatterEstimatedTime = {
    format: {
      date: 'YYYY-MM-DD[T]HH:mm:ssZ',
      time: 'hh:mm A',
    },
    subtracFrom: 2,
    addUntil: 2,
  },
): EstimatedDateTime => {
  const fromDate = moment(date).subtract(options.subtracFrom, 'hours');
  const toDate = moment(date).add(options.addUntil, 'hours');

  return {
    from: {
      date: formatterDate(fromDate, { format: options.format?.date }),
      time: formatterDate(fromDate, { format: options.format?.time }),
    },
    to: {
      date: formatterDate(toDate, { format: options.format?.date }),
      time: formatterDate(toDate, { format: options.format?.time }),
    },
  };
};
