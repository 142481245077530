import { ReactElement, useCallback } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import { Stack, Typography, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useToggle } from 'react-use';

import { useLazyRequest } from '~hooks/index';
import { searchOrder } from '~services/order';
import { OrderBase } from '~services/order/types';
import { PATHS } from '~constants/paths';
import { API_STATUS_CODES } from '~constants/apiStatusCodes';
import { replaceParamsPath } from '~utils/router';
import { hasError, getHelperText } from '~utils/formHelper';

import TrackingCodeInputMask from '../TrackingCodeInputMask';
import { SearcOrderFormProps } from './types';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';

const SearchOrderForm = ({ companyId }: SearcOrderFormProps): ReactElement => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isNotFoundOrder, toggleIsNotFoundOrder] = useToggle(false);

  const [, loadingSearchOrder, , executeSearchOrder] = useLazyRequest({
    request: searchOrder,
    withPostSuccess: (response) => {
      const { routeItemId } = response.data?.data as OrderBase;

      navigate(
        replaceParamsPath(PATHS.ORDER, {
          ':companyId': companyId,
          ':orderId': routeItemId,
        }),
      );
    },
    withPostFailure: (error) => {
      if (
        error.data?.data?.errorCode === API_STATUS_CODES.resourceNotFound ||
        error.status === API_STATUS_CODES.badRequest
      ) {
        toggleIsNotFoundOrder(true);
      } else {
        enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
          variant: 'error',
        });
      }
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      toggleIsNotFoundOrder(false);

      await executeSearchOrder({
        companyId,
        routeCode: values[FIELDS_NAME.TRACKING_CODE],
      });
      setSubmitting(false);
    },
    [toggleIsNotFoundOrder, executeSearchOrder, companyId],
  );

  const { handleSubmit, touched, errors, getFieldProps, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  return (
    <Stack
      spacing={5}
      component="form"
      onSubmit={handleSubmit}
      sx={{
        paddingY: { xs: 0, lg: 6 },
        maxWidth: { xs: '100%', sm: 300 },
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h5" fontWeight={500} textAlign="center">
          Seguimiento de ordenes
        </Typography>

        <Typography variant="body2" color="text.secondary" textAlign="center">
          Ingresa el número de seguimiento para poder visualizar en tiempo real
          el estado de la orden.
        </Typography>
      </Stack>

      <Stack spacing={2}>
        <TextField
          size="medium"
          label="Seguí tu orden"
          autoComplete="off"
          error={hasError(touched, errors, FIELDS_NAME.TRACKING_CODE)}
          helperText={getHelperText(touched, errors, FIELDS_NAME.TRACKING_CODE)}
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: TrackingCodeInputMask as any,
          }}
          InputLabelProps={{ shrink: true }}
          {...getFieldProps(FIELDS_NAME.TRACKING_CODE)}
        />

        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          color="primary"
          loading={loadingSearchOrder || isSubmitting}
        >
          Buscar orden
        </LoadingButton>

        {isNotFoundOrder && (
          <Alert variant="outlined" severity="warning" icon={false}>
            No hemos encontrado ningúna orden con el número de seguimiento
            ingresado. Por favor, verifica el número de seguimiento e intentalo
            nuevamente.
          </Alert>
        )}
      </Stack>
    </Stack>
  );
};

export default SearchOrderForm;
