import { ReactElement, useCallback, useMemo, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import {
  useAppSelector,
  useAppDispatch,
  useOrderStateText,
  useDownloadEvidencesOrderPDF,
} from '~hooks/index';
import { selectCompanyState } from '~store/slices/company';
import { selectOrderState, resetOrder } from '~store/slices/order';
import { replaceParamsPath } from '~utils/router';
import {
  isOrderFinalizedSuccess,
  isOrderInProgress,
  isOrderCompleted,
  isOrderInTracking,
  isDelayedOrder,
  validateOrderState,
} from '~utils/order';
import { toAbsoluteUrl } from '~utils/assets';
import { PATHS } from '~constants/paths';
import { RouteItemStateType } from '~globals/enums';
import {
  DescriptionWithIconBox,
  StepperOrderState,
  EstimatedDeliveryInfo,
  DeliveryCodeInfo,
  ComplaintChannelButton,
  ArticlesDialog,
} from '~components/index';

import {
  OrderInfoContainer,
  OrderInfoText,
  OrderInfoTitle,
  OrderInfoContent,
  OrderInfoContentLabel,
  OrderInfoContentLabelTrackingCodeTypography,
  OrderInfoContentDescription,
  OrderInfoContentStepper,
  OrderInfoContentState,
  OrderInfoContentInfo,
  OrderInfoActions,
  OrderInfoActionsButton,
  OrderInfoActionsButtonLoading,
} from './styles';

const OrderInfo = (): ReactElement => {
  const { companyId, orderId } = useParams() as {
    companyId: string;
    orderId: string;
  };
  const navigate = useNavigate();
  const { data: currenCompany } = useAppSelector(selectCompanyState);
  const { data: currentOrder } = useAppSelector(selectOrderState);
  const dispatch = useAppDispatch();

  const routeItemStateTypeId = useMemo(
    () => currentOrder?.routeItemStateTypeId ?? RouteItemStateType.OnAgenda,
    [currentOrder?.routeItemStateTypeId],
  );

  const itemTasks = useMemo(
    () => currentOrder?.itemTasks ?? [],
    [currentOrder],
  );

  const {
    getName: getStateName,
    getLabel: getStateLabel,
    getDescription: getStateDescription,
  } = useOrderStateText({
    routeItemStateTypeId,
    contactName: currentOrder?.contactName ?? null,
    estimatedArrivalDateTime: currentOrder?.estimatedArrivalDateTime ?? null,
    realArrivalDateTime: currentOrder?.realArrivalDateTime ?? null,
  });

  const redirectToSurvey = useCallback(() => {
    navigate(
      replaceParamsPath(PATHS.SURVEY, {
        ':companyId': companyId,
        ':orderId': orderId,
      }),
    );
  }, [navigate, companyId, orderId]);

  const {
    loading: loadingDowloadPDF,
    onDownloadPDF: handleDowloadEvidencesOrderPDF,
  } = useDownloadEvidencesOrderPDF(currentOrder?.trackingCode);

  const [showArticleDialog, setShowArticleDialog] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetOrder());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrderInfoContainer>
      <OrderInfoContent>
        <OrderInfoContentLabel>
          <OrderInfoContentLabelTrackingCodeTypography
            color="text.secondary"
            textAlign="center"
          >
            <span>Seguimiento de orden</span>
            <span>{currentOrder?.trackingCode}</span>
          </OrderInfoContentLabelTrackingCodeTypography>

          <OrderInfoTitle textAlign="center">{getStateLabel()}</OrderInfoTitle>

          {isOrderInTracking(routeItemStateTypeId) &&
            currentOrder?.securityDeliveryCode && (
              <DeliveryCodeInfo code={currentOrder.securityDeliveryCode} />
            )}
        </OrderInfoContentLabel>

        <OrderInfoContentDescription>
          <OrderInfoText
            color="text.secondary"
            textAlign={{ xs: 'center', md: 'left' }}
          >
            {getStateDescription()}
          </OrderInfoText>
        </OrderInfoContentDescription>

        <OrderInfoContentStepper>
          <StepperOrderState routeItemStateTypeId={routeItemStateTypeId} />
        </OrderInfoContentStepper>

        <OrderInfoContentState>
          <OrderInfoText color="text.secondary">Estado</OrderInfoText>

          <Typography
            variant="body1"
            fontWeight={500}
            color="primary"
            textAlign={{ xs: 'center', md: 'left' }}
          >
            {getStateName()}
          </Typography>
        </OrderInfoContentState>

        <OrderInfoContentInfo>
          {currentOrder?.driverName && (
            <DescriptionWithIconBox
              iconUrl={toAbsoluteUrl('/images/user.svg')}
              label="Chofer"
              description={currentOrder?.driverName ?? ''}
            />
          )}

          {/* <DescriptionWithIconBox
            iconUrl={toAbsoluteUrl('/images/user.svg')}
            label="Establecimiento"
            description="Melon Store"
          /> */}
        </OrderInfoContentInfo>
      </OrderInfoContent>

      {isOrderInProgress(routeItemStateTypeId) &&
        currentOrder?.estimatedArrivalDateTime &&
        !isDelayedOrder(currentOrder.estimatedArrivalDateTime) && (
          <EstimatedDeliveryInfo date={currentOrder.estimatedArrivalDateTime} />
        )}

      <OrderInfoActions>
        {currenCompany?.showArticles &&
          itemTasks.length > 0 &&
          validateOrderState(
            [RouteItemStateType.OnAgenda, RouteItemStateType.InProgress],
            routeItemStateTypeId,
          ) && (
            <OrderInfoActionsButton
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => setShowArticleDialog(true)}
            >
              Ver artículos
            </OrderInfoActionsButton>
          )}

        {currenCompany &&
          currentOrder?.trackingCode &&
          !isOrderCompleted(routeItemStateTypeId) && (
            <ComplaintChannelButton
              channelTypeId={currenCompany.complaintChannelTypeId}
              address={currenCompany.complaintAddress}
              orderTrackingCode={currentOrder.trackingCode}
            />
          )}

        {currenCompany?.allowUsersDownloadEvidencePdf &&
          isOrderCompleted(routeItemStateTypeId) && (
            <OrderInfoActionsButtonLoading
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleDowloadEvidencesOrderPDF}
              loading={loadingDowloadPDF}
            >
              Descargar comprobante de visita
            </OrderInfoActionsButtonLoading>
          )}

        {isOrderFinalizedSuccess(routeItemStateTypeId) && (
          <OrderInfoActionsButton
            variant="contained"
            color="primary"
            size="large"
            onClick={redirectToSurvey}
          >
            Califica tu experiencia
          </OrderInfoActionsButton>
        )}
      </OrderInfoActions>

      <ArticlesDialog
        open={showArticleDialog}
        onClose={() => setShowArticleDialog(false)}
        articles={itemTasks}
      />
    </OrderInfoContainer>
  );
};

export default OrderInfo;
