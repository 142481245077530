import { ReactElement, useMemo } from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';

import { useAppSelector } from '~hooks/index';
import { selectCompanyState } from '~store/slices/company';
import { selectOrderState } from '~store/slices/order';
import { CompanyInfo, LoadingFallback, Map } from '~components/index';
import { toAbsoluteUrl } from '~utils/assets';
import { isOrderInProgress } from '~utils/order';
import { EXTERNALS_URLS } from '~constants/paths';

import { LayoutProps } from './types';
import {
  classes,
  LayoutContainer,
  LayoutHeroContainer,
  LayoutHeroImg,
  LayoutMainContainer,
  LayoutMainContentContainer,
  LayoutMainFooterContainer,
  LayoutFooterLink,
  LayoutFooterLogo,
} from './styles';

const Layout = ({
  children,
  loading,
  validateShowMap = false,
  className,
}: LayoutProps): ReactElement => {
  const { data: currenCompany } = useAppSelector(selectCompanyState);

  const { data: currentOrder } = useAppSelector(selectOrderState);

  const giverCompanyName = useMemo(
    () =>
      currentOrder?.giverCompanyId === currenCompany?.companyId
        ? undefined
        : currentOrder?.giverCompanyName,
    [currentOrder, currenCompany],
  );

  const routeItemStateTypeId = useMemo(
    () => currentOrder?.routeItemStateTypeId,
    [currentOrder?.routeItemStateTypeId],
  );

  const isValidStateToShowMap = useMemo(
    () =>
      routeItemStateTypeId !== undefined &&
      isOrderInProgress(routeItemStateTypeId),
    [routeItemStateTypeId],
  );

  const showTrackingMapByCurrentCompany = useMemo(
    () => Boolean(currenCompany?.trackingShowTrackingMap),
    [currenCompany?.trackingShowTrackingMap],
  );

  const showTrackingMap = useMemo(
    () =>
      showTrackingMapByCurrentCompany && validateShowMap
        ? isValidStateToShowMap
        : false,
    [showTrackingMapByCurrentCompany, validateShowMap, isValidStateToShowMap],
  );

  if (loading) return <LoadingFallback />;

  return (
    <LayoutContainer className={classNames(className, classes.container)}>
      <LayoutHeroContainer className={classes.hero}>
        {!showTrackingMap && <LayoutHeroImg />}

        {showTrackingMap && <Map />}
      </LayoutHeroContainer>

      <LayoutMainContainer className={classes.main}>
        {currenCompany && (
          <CompanyInfo
            name={currenCompany.companyName}
            urlAvatar={currenCompany.companyLogoUrl}
            giverCompanyName={giverCompanyName}
            roundedMobile
            floatMobile
          />
        )}

        <LayoutMainContentContainer className={classes.mainContent}>
          {children}
        </LayoutMainContentContainer>

        <LayoutMainFooterContainer className={classes.mainFooter}>
          <LayoutFooterLink
            href={EXTERNALS_URLS.COMMERCIAL_PAGE}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <Typography variant="caption" color="text.secondary">
              Con la tecnología de
            </Typography>

            <LayoutFooterLogo
              src={toAbsoluteUrl('/logo.svg')}
              alt="Routix Logo"
            />
          </LayoutFooterLink>
        </LayoutMainFooterContainer>
      </LayoutMainContainer>
    </LayoutContainer>
  );
};

export default Layout;
