export enum RouteItemStateType {
  New = 0,
  OnAgenda = 1,
  InProgress = 2,
  FinalizedSuccess = 3,
  FinalizedError = 4,
  WithoutVisiting = 5,
}

export enum SurveyRatings {
  Bad = '1',
  Improvable = '2',
  Appropriate = '4',
  Optimum = '5',
}

export enum ComplaintChannelTypeId {
  None = 0,
  Email = 1,
  Whatsapp = 2,
}

export enum OrderTaskStatusType {
  Pending = 0,
  Success = 1,
  Error = 2,
}
