import { useSnackbar } from 'notistack';
import { useBoolean } from 'react-use';

import { useAppDispatch, useRequest } from '~hooks/index';
import { getCompanyById } from '~services/company';
import { CompanyBase } from '~services/company/types';
import { fetchedCompany } from '~store/slices/company';
import { API_STATUS_CODES } from '~constants/apiStatusCodes';
import { EXTERNALS_URLS } from '~constants/paths';
import { setDocumentTitle } from '~utils/document';
import { LoadingFetch } from '~globals/commons';

interface FetchCompanyParamsActionsChangeTitleOptions {
  setenceBeforeCompanyName: string;
}
interface FetchCompanyParamsActions {
  changeTitleWithCompanyNameOptions?: FetchCompanyParamsActionsChangeTitleOptions;
}

const DEFAULT_FETCH_COMPANY_ACTIONS: FetchCompanyParamsActions = {
  changeTitleWithCompanyNameOptions: undefined,
};

export const useFetchCompany = (
  companyId: string,
  actions: FetchCompanyParamsActions = DEFAULT_FETCH_COMPANY_ACTIONS,
): LoadingFetch => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const { changeTitleWithCompanyNameOptions } = actions;

  const [loading, toggleLoading] = useBoolean(true);

  useRequest({
    request: getCompanyById,
    payload: companyId,
    withPostSuccess: (response) => {
      const companyResponse = response.data?.data as CompanyBase;
      dispatch(fetchedCompany(companyResponse));

      if (changeTitleWithCompanyNameOptions) {
        setDocumentTitle(
          // eslint-disable-next-line max-len
          `${changeTitleWithCompanyNameOptions.setenceBeforeCompanyName} ${companyResponse.companyName}`,
        );
      }
    },
    withPostFailure: (error) => {
      if (
        error.data?.data?.errorCode === API_STATUS_CODES.resourceNotFound ||
        error.status === API_STATUS_CODES.badRequest
      ) {
        window.location.assign(EXTERNALS_URLS.HELP_ARTICLE);
      } else {
        enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
          variant: 'error',
        });
      }
    },
    withPostFinally() {
      toggleLoading(false);
    },
  });

  return { loading };
};
