import { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useBoolean, useInterval } from 'react-use';

import { useAppDispatch, useRequest } from '~hooks/index';
import { getOrderInfoById } from '~services/order';
import { OrderExtended } from '~services/order/types';
import { fetchedOrder } from '~store/slices/order';
import { API_STATUS_CODES } from '~constants/apiStatusCodes';
import { EXTERNALS_URLS } from '~constants/paths';
import { validateOrderState } from '~utils/order';
import { LoadingFetch } from '~globals/commons';
import { RouteItemStateType } from '~globals/enums';

const DEFAULT_INTERVAL = 15000;
const COMPLETED_STATES = [
  RouteItemStateType.FinalizedSuccess,
  RouteItemStateType.FinalizedError,
  RouteItemStateType.WithoutVisiting,
];

interface FetchOrderParamsActions {
  enabledInterval?: boolean;
}

const DEFAULT_FETCH_ORDER_ACTIONS: FetchOrderParamsActions = {
  enabledInterval: true,
};

export const useFetchOrder = (
  orderId: string,
  actions: FetchOrderParamsActions = DEFAULT_FETCH_ORDER_ACTIONS,
): LoadingFetch => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const { enabledInterval } = actions;

  const [isRunningInterval, toggleIsRunningInterval] = useBoolean(
    Boolean(enabledInterval),
  );

  const [loading, toggleLoading] = useBoolean(true);

  const [, , , handleFetch] = useRequest({
    request: getOrderInfoById,
    payload: orderId,
    withPostSuccess: (response) => {
      const orderResponse = response.data?.data as OrderExtended;
      dispatch(fetchedOrder(orderResponse));

      if (
        validateOrderState(COMPLETED_STATES, orderResponse.routeItemStateTypeId)
      ) {
        toggleIsRunningInterval(false);
      }
    },
    withPostFailure: (error) => {
      if (
        error.data?.data?.errorCode === API_STATUS_CODES.resourceNotFound ||
        error.status === API_STATUS_CODES.badRequest
      ) {
        window.location.assign(EXTERNALS_URLS.HELP_ARTICLE);
      } else {
        enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
          variant: 'error',
        });
      }
    },
    withPostFinally() {
      toggleLoading(false);
    },
  });

  const delayInterval = useMemo(
    () => (isRunningInterval ? DEFAULT_INTERVAL : null),
    [isRunningInterval],
  );

  useInterval(() => handleFetch(orderId), delayInterval);

  return { loading };
};
