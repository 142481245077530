import { useNavigate } from 'react-router-dom';

import { OrderSearch } from '~services/order/types';
import { useRequest } from '~hooks/index';
import { searchOrderByTrackingCode } from '~services/order';

interface SearchAndRedirectOrderByTrackingCodeParams {
  routeCode: string;
  getUrlRedirect: (data: OrderSearch) => string;
}

interface SearchAndRedirectOrderByTrackingCodeReturn {
  loading: boolean;
  notFound: boolean;
}

export const useSearchAndRedirectOrderByTrackingCode = ({
  routeCode,
  getUrlRedirect,
}: SearchAndRedirectOrderByTrackingCodeParams): SearchAndRedirectOrderByTrackingCodeReturn => {
  const navigate = useNavigate();

  const [, loading, error] = useRequest({
    request: searchOrderByTrackingCode,
    payload: routeCode,
    withPostSuccess: (response) => {
      const { companyId, routeItemId } = response.data?.data as OrderSearch;

      const urlRedirect = getUrlRedirect({ companyId, routeItemId });

      navigate(urlRedirect);
    },
  });

  return { loading, notFound: Boolean(error) };
};
