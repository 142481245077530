import { useCallback, useMemo } from 'react';
import { MomentInput } from 'moment';
import { upperFirst, get } from 'lodash';

import { RouteItemStateType } from '~globals/enums';
import { OrderExtended } from '~services/order/types';
import { formatterDate, formatterEstimatedDateTime } from '~utils/formatter';
import { isDelayedOrder } from '~utils/order';

interface OrderStateTextItem {
  getName: () => string;
  getLabel: () => string;
  getDescription: () => string;
}

type OrderStateText = Record<RouteItemStateType, OrderStateTextItem>;

type OrderStateTextHookParams = Pick<
  OrderExtended,
  | 'routeItemStateTypeId'
  | 'contactName'
  | 'estimatedArrivalDateTime'
  | 'realArrivalDateTime'
>;

const getParseObjectDate = (
  date: MomentInput,
): { currentDay: string; date: string; time: string } => ({
  currentDay: upperFirst(formatterDate(date, { format: 'dddd' })),
  date: formatterDate(date, { format: 'DD/MM/YYYY' }),
  time: formatterDate(date, { format: 'hh:mm A' }),
});

export const useOrderStateText = ({
  routeItemStateTypeId,
  contactName,
  estimatedArrivalDateTime,
  realArrivalDateTime,
}: OrderStateTextHookParams): OrderStateTextItem => {
  const getLabelWithContactName = useCallback(
    (label: string) => {
      if (contactName) return `${contactName}, ${label}`;

      return upperFirst(label);
    },
    [contactName],
  );

  const isDelayed = useCallback(
    () => isDelayedOrder(estimatedArrivalDateTime),
    [estimatedArrivalDateTime],
  );

  const orderStateTextList = useMemo<OrderStateText>(
    () => ({
      [RouteItemStateType.New]: {
        getName: () => '',
        getLabel: () => '',
        getDescription: () => '',
      },
      [RouteItemStateType.OnAgenda]: {
        getName: () => 'Orden en agenda',
        getLabel: () => getLabelWithContactName('tu orden está en agenda!'),
        getDescription: () => {
          const { currentDay, date } = getParseObjectDate(
            estimatedArrivalDateTime,
          );

          // eslint-disable-next-line max-len
          return `Estimamos que te estaremos visitando el ${currentDay} ${date}. Te informaremos cuando nos encontremos en camino.`;
        },
      },
      [RouteItemStateType.InProgress]: {
        getName: () => (isDelayed() ? 'Orden demorada' : 'Orden en camino'),
        getLabel: () =>
          getLabelWithContactName(
            isDelayed() ? 'tu orden está demorada' : 'tu orden está en camino!',
          ),
        getDescription: () => {
          const { from, to } = formatterEstimatedDateTime(
            estimatedArrivalDateTime,
          );

          if (isDelayed()) {
            // eslint-disable-next-line max-len
            return `Hemos detectado una demora en la visita a tu domicilio (${from.time} - ${to.time}). Para más información comunícate con tu asesor.`;
          }

          // eslint-disable-next-line max-len
          return `Estimamos que te estaremos visitando entre las ${from.time} y ${to.time} en tu domicilio.`;
        },
      },
      [RouteItemStateType.FinalizedSuccess]: {
        getName: () => 'Orden completada',
        getLabel: () => getLabelWithContactName('tu visita ha sido realizada!'),
        getDescription: () => {
          const { currentDay, date, time } =
            getParseObjectDate(realArrivalDateTime);

          // eslint-disable-next-line max-len
          return `Hemos visitado tu domicilio el ${currentDay} ${date} a las ${time}.`;
        },
      },
      [RouteItemStateType.FinalizedError]: {
        getName: () => 'Orden no completada',
        getLabel: () =>
          getLabelWithContactName('hubo un inconveniente con tu visita'),
        getDescription: () => {
          const { currentDay, date, time } =
            getParseObjectDate(realArrivalDateTime);

          // eslint-disable-next-line max-len
          return `Hemos visitado tu domicilio el ${currentDay} ${date} a las ${time}.`;
        },
      },
      [RouteItemStateType.WithoutVisiting]: {
        getName: () => 'Domicilio sin visitar',
        getLabel: () =>
          getLabelWithContactName('no hemos podido gestionar la orden'),
        getDescription: () => {
          const { currentDay, date, time } =
            getParseObjectDate(realArrivalDateTime);

          // eslint-disable-next-line max-len
          return `Tu orden fue marcada como sin visitar el ${currentDay} ${date} a las ${time}.`;
        },
      },
    }),
    [
      getLabelWithContactName,
      estimatedArrivalDateTime,
      realArrivalDateTime,
      isDelayed,
    ],
  );

  const getCurrentOrderStateText = useMemo<OrderStateTextItem>(
    () => get(orderStateTextList, routeItemStateTypeId),
    [orderStateTextList, routeItemStateTypeId],
  );

  return getCurrentOrderStateText;
};
