import {
  lighten,
  darken,
  SimplePaletteColorOptions,
  getContrastRatio,
} from '@mui/material/styles';
import {
  PaletteTonalOffset,
  light,
  dark,
} from '@mui/material/styles/createPalette';

export const createAugmentColor = ({
  mainColor,
  tonalOffset: tonalOffsetParam,
  contrastText: contrastTextParam,
  contrastThreshold: contrastThresholdParam,
}: {
  mainColor: string;
  tonalOffset: PaletteTonalOffset;
  contrastThreshold: number;
  contrastText?: string;
}): SimplePaletteColorOptions => {
  let tonalOffsetLight = 0;
  let tonalOffsetDark = 0;
  let contrastText = contrastTextParam;

  if (typeof tonalOffsetParam === 'number') {
    tonalOffsetLight = tonalOffsetParam;
    tonalOffsetDark = tonalOffsetParam * 1.5;
  }

  if (typeof tonalOffsetParam === 'object') {
    tonalOffsetLight = tonalOffsetParam.light;
    tonalOffsetDark = tonalOffsetParam.dark;
  }

  if (!contrastText) {
    contrastText =
      getContrastRatio(mainColor, dark.text.primary) >= contrastThresholdParam
        ? dark.text.primary
        : light.text.primary;
  }

  return {
    main: mainColor,
    light: lighten(mainColor, tonalOffsetLight),
    dark: darken(mainColor, tonalOffsetDark * 1.5),
    contrastText,
  };
};
