import { combineReducers } from '@reduxjs/toolkit';

import companyReducer from './company';
import orderReducer from './order';

const rootReducers = combineReducers({
  company: companyReducer,
  order: orderReducer,
});

export default rootReducers;
