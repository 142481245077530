import { create } from 'apisauce';

import { camelCaseSerializer } from '~utils/serializer';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const API = create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

API.addRequestTransform((config) => {
  if (config.data && !(config.data instanceof FormData))
    config.data = camelCaseSerializer.serialize(config.data);
});

API.addResponseTransform((response) => {
  if (response.data && !(response.data instanceof Blob))
    response.data = camelCaseSerializer.serialize(response.data);
});

export default API;
