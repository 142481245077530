import { styled, Stack } from '@mui/material';

import Layout from '../Layout';
import { classes as LayoutClasses } from '../Layout/styles';

export const NotFoundOrderContainer = styled(Layout)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    [`& .${LayoutClasses.main}`]: {
      gridTemplateRows: '1fr auto',
    },
  },

  [`& .${LayoutClasses.mainContent}`]: {
    alignItems: 'center',
  },
}));

export const NotFoundOrderContent = styled(Stack)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  maxWidth: '100%',

  [theme.breakpoints.up('sm')]: {
    maxWidth: 300,
  },

  [theme.breakpoints.up('lg')]: {
    paddingTop: 6,
    paddingBottom: 6,
  },
}));
