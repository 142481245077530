import { ReactElement, useMemo } from 'react';
import { Typography } from '@mui/material';

import { EstimatedDeliveryInfoProps } from './types';
import { EstimatedDeliveryInfoContainer } from './styles';

import { formatterEstimatedDateTime } from '~utils/formatter';

const EstimatedDeliveryInfoInfo = ({
  date,
}: EstimatedDeliveryInfoProps): ReactElement => {
  const { from, to } = useMemo(() => formatterEstimatedDateTime(date), [date]);

  return (
    <EstimatedDeliveryInfoContainer>
      <Typography variant="body2" fontWeight={500} color="text.secondary">
        Horario estimado de entrega
      </Typography>

      <Typography variant="h6" fontWeight={500}>
        {`${from.time} - ${to.time}`}
      </Typography>
    </EstimatedDeliveryInfoContainer>
  );
};

export default EstimatedDeliveryInfoInfo;
