import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useBoolean } from 'react-use';

import { useRequest } from '~hooks/index';
import { validateExistsSurveyById } from '~services/survey';
import { LoadingFetch } from '~globals/commons';

interface FetchValidateExistsSurveyReturn extends LoadingFetch {
  exists: boolean;
}

export const useFetchValidateExistsSurvey = (
  orderId: string,
): FetchValidateExistsSurveyReturn => {
  const { enqueueSnackbar } = useSnackbar();

  const [exists, setExists] = useState<boolean>(false);
  const [loading, toggleLoading] = useBoolean(true);

  useRequest({
    request: validateExistsSurveyById,
    payload: orderId,
    withPostSuccess: (response) => {
      const validateExistsSurveyResponse = response.data?.data as boolean;

      setExists(validateExistsSurveyResponse);
    },
    withPostFailure: () => {
      enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
        variant: 'error',
      });
    },
    withPostFinally() {
      toggleLoading(false);
    },
  });

  return { loading, exists };
};
