import * as Yup from 'yup';

import { SurveyRatings } from '~globals/enums';

export const FIELDS_NAME = {
  DELAY_IN_DELIVERIES: 'dealyInDeliveries',
  PACKAGING_QUALITY: 'packagingQuality',
  ATTENTION_RECEIVED: 'attentionReceived',
  SUGGESTIONS: 'suggestions',
} as const;

export const initialValues = {
  [FIELDS_NAME.DELAY_IN_DELIVERIES]: SurveyRatings.Optimum,
  [FIELDS_NAME.PACKAGING_QUALITY]: SurveyRatings.Optimum,
  [FIELDS_NAME.ATTENTION_RECEIVED]: SurveyRatings.Optimum,
  [FIELDS_NAME.SUGGESTIONS]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.DELAY_IN_DELIVERIES]: Yup.string(),
  [FIELDS_NAME.PACKAGING_QUALITY]: Yup.string(),
  [FIELDS_NAME.ATTENTION_RECEIVED]: Yup.string(),
  [FIELDS_NAME.SUGGESTIONS]: Yup.string(),
});
