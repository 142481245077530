import { ReactElement } from 'react';
import { Typography, Stack } from '@mui/material';

import { getInitialsNameLetterUpperCase } from '~utils/company';

import { CompanyInfoProps } from './types';
import { CompanyInfoContainer, CompanyInfoAvatar } from './styles';

const CompanyInfo = ({
  name,
  urlAvatar,
  giverCompanyName,
  roundedMobile = true,
  floatMobile = true,
}: CompanyInfoProps): ReactElement => (
  <CompanyInfoContainer ownerState={{ roundedMobile, floatMobile }}>
    <CompanyInfoAvatar alt={name} src={urlAvatar}>
      {getInitialsNameLetterUpperCase(name as string)}
    </CompanyInfoAvatar>

    <Stack>
      <Typography
        component="h3"
        variant={giverCompanyName ? 'body2' : 'inherit'}
        fontWeight={giverCompanyName ? undefined : 'bold'}
      >
        {name}
      </Typography>

      {giverCompanyName && (
        <Typography component="h3" variant="body1" fontWeight="bold">
          {`Al servicio de ${giverCompanyName}`}
        </Typography>
      )}
    </Stack>
  </CompanyInfoContainer>
);

export default CompanyInfo;
