import { ReactElement, useMemo } from 'react';

import { RatingProps } from './types';
import {
  RatingContainer,
  RatingLabel,
  RatingRadioGroup,
  RatingRadioLabelContainer,
  RatingRadioLabel,
  RatingRadioRadio,
  RatingRadioLabelConnectorContainer,
  RatingRadioLabelConnectorContent,
} from './styles';

const Rating = ({
  label,
  value,
  name,
  options,
  onChange,
}: RatingProps): ReactElement => {
  const id = useMemo(() => `rating-${name}`, [name]);

  return (
    <RatingContainer>
      <RatingLabel id={id}>{label}</RatingLabel>

      <RatingRadioGroup
        name={name}
        value={value}
        onChange={onChange}
        aria-labelledby={id}
        row
      >
        {options.map((option, index) => (
          <RatingRadioLabelContainer key={`rating-item-${option.label}`}>
            <RatingRadioLabel
              value={option.value}
              control={
                <RatingRadioRadio
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                />
              }
              label={option.label}
              labelPlacement="bottom"
            />

            {index !== 0 && (
              <RatingRadioLabelConnectorContainer>
                <RatingRadioLabelConnectorContent />
              </RatingRadioLabelConnectorContainer>
            )}
          </RatingRadioLabelContainer>
        ))}
      </RatingRadioGroup>
    </RatingContainer>
  );
};

export default Rating;
