import { includes } from 'lodash';
import moment from 'moment';

import { RouteItemStateType } from '~globals/enums';
import { Nullable } from '~globals/commons';
import { formatterEstimatedDateTime } from '~utils/formatter';

export const validateOrderState = (
  states: RouteItemStateType[],
  currentState: RouteItemStateType,
): boolean => includes(states, currentState);

export const isOrderCompleted = (currentState: RouteItemStateType): boolean =>
  validateOrderState(
    [RouteItemStateType.FinalizedSuccess, RouteItemStateType.FinalizedError],
    currentState,
  );

export const isOrderFinalizedSuccess = (
  currentState: RouteItemStateType,
): boolean =>
  validateOrderState([RouteItemStateType.FinalizedSuccess], currentState);

export const isOrderInProgress = (currentState: RouteItemStateType): boolean =>
  validateOrderState([RouteItemStateType.InProgress], currentState);

export const isOrderInTracking = (currentState: RouteItemStateType): boolean =>
  validateOrderState(
    [RouteItemStateType.OnAgenda, RouteItemStateType.InProgress],
    currentState,
  );

export const isDelayedOrder = (
  estimatedArrivalDateTime: Nullable<string>,
): boolean => {
  if (estimatedArrivalDateTime) {
    const {
      to: { date: maxEstimedArrivalDate },
    } = formatterEstimatedDateTime(estimatedArrivalDateTime);

    return moment(maxEstimedArrivalDate).isBefore(moment(), 'minutes');
  }

  return false;
};
