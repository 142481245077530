import { chain, words, last, toUpper } from 'lodash';

const trimHead = (str: string) => chain(str).head().trim();

export const getInitialsNameLetterUpperCase = (name: string): string => {
  const [firstName, ...restName] = words(name);
  let initials = chain(firstName).toArray().slice(0, 2).join('').value();

  const lastName = last(restName);

  if (lastName) {
    initials = `${trimHead(firstName)}${trimHead(lastName)}`;
  }

  return toUpper(initials);
};
