import { useEffect } from 'react';

import { RedirectProps } from './types';

const Redirect = ({ url }: RedirectProps): null => {
  useEffect(() => {
    window.location.assign(url);
  }, [url]);

  return null;
};

export default Redirect;
