import { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';

import store from '~store/index';
import theme from '~config/theme';
import { SnackBarProvider } from '~components/index';

import BaseRoutes from './routes';

import 'moment/locale/es';

const App = (): ReactElement => (
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <SnackBarProvider>
          <BaseRoutes />
        </SnackBarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);

export default App;
